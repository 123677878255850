<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Application Contrat de travail" />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Configuration</div>
              </v-row>
            </v-card-title>

            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular mt-2">
                    types utilisateurs
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <!-- Affichage des type user à associé -->
                  <div v-for="(ut, i) in associateUsersTypes" :key="i">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>{{ ut.label }}</div>
                      <v-btn
                        icon
                        color="primary"
                        @click="removeAssociateUserTypes(ut)"                
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>
                  <!-- Affichage de la liste des sous-activités associables -->
                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete  
                        v-model="selectedUserType"                      
                        :items="availableUserType"  
                        item-text="label"                      
                        return-object
                        placeholder="Associer un type d'utilisateur"
                        hint="Associer un type d'utilisateur puis appuyer sur le bouton '+'"
                        clearable   
                        no-data-text="aucun type utlisateur"
                        v-on:keyup.enter="addUserType(selectedUserType)"                    
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addUserType(selectedUserType)"
                        :disabled="!selectedUserType"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <v-card-text>
              <v-row no-gutters class="mb-5 mt-1" align="center">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular mt-2">
                    natures relation de travail
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <!-- Affichage des natures à associé -->
                  <div v-for="(nrt, i) in associateWorkRealtionshipType" :key="i+1000">
                    <v-row
                      no-gutters
                      align="center"
                      justify="space-between"
                      class="mb-1"
                    >
                      <div>{{ nrt.label }}</div>
                      <v-btn
                        icon
                        color="primary"
                        @click="removeWorkRelationshipType(nrt)"               
                      >
                        <v-icon>mdi-window-close</v-icon>
                      </v-btn>
                    </v-row>
                  </div>
                  <!-- Affichage de la liste des sous-activités associables -->
                  <div>
                    <v-row no-gutters align="center" justify="space-between">
                      <v-autocomplete 
                        v-model="selectedWorkelationshipType"                       
                        :items="availableWorkRelationshipType"   
                        item-text="label"                     
                        return-object
                        placeholder="Associer une nature de relation de travail"
                        hint="Associer une nature de relation de travail puis appuyer sur le bouton '+'"
                        clearable     
                        no-data-text="aucune nature relation de travail"  
                        v-on:keyup.enter="addWorkRelationshipType(selectedWorkelationshipType)"                
                      >
                      </v-autocomplete>
                      <v-btn
                        color="primary"
                        icon
                        @click="addWorkRelationshipType(selectedWorkelationshipType)"
                        :disabled="!selectedWorkelationshipType"
                        ><v-icon>mdi-plus</v-icon></v-btn
                      >
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              outlinedq
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
              >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

     <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";
import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import { AppWorksContractsService } from "@/service/conf/app_works_contracts_service.js";
import UsersTypeService from "@/service/user/user_type_service.js";
import { WorkRelationshipTypesService } from "@/service/user/work_relationship_type_service.js";

import * as exceptions from "@/service/exception_to_message.js";


export default {
  name: "ConfContrat",
  components: {
    Workflow,
    TitleAndReturnComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],

  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service des accès aux apis */
      serviceAppWorksContract: null,
      serviceUserType: null,
      serviceWorkRelationshipType: null,
      
      /** les types utilisateurs source */
      sourceUsersTypes: [],
      /** les types de relation de travail source */
      sourceWorkRelationshipTypes: [],

      /** les types d'utilisateurs associés */
      associateUsersTypes: [],
      /** les types de relation de travail associés */
      associateWorkRealtionshipType: [],

      /** tous les types d'utilisateurs */
      allUsersTypes: [],
      /** tous les types de realtion de travail */
      allWorksRelationType: [],

      /** le type d'utilisateur sélectionné */
      selectedUserType: null,
      /** le type de realation de travail sélectionné */
      selectedWorkelationshipType: null,
    };
  },

  methods: {

    /** Chargement des données */
    async load() {
      try {
        this.loading = true;

        /** Récupération de tous les types utilisateurs */
        this.allUsersTypes = await this.serviceUserType.getAllUsersType();
        /** Récupération de tous les types de relation de travail */
        this.allWorksRelationType = await this.serviceWorkRelationshipType.getAll();

        /** Récupération de tous les types utilisateurs de la conf */
        let usersTypeConf = await this.serviceAppWorksContract.getListUserTypes();
        /** L'api conf retourne la liste des ids des types utilisateurs */
        for (let i = 0; i < usersTypeConf.length; i++) {
          let user = this.allUsersTypes.find((u) => u.id == usersTypeConf[i]);
          if (user) {
            this.associateUsersTypes.push(user);
          }
        }
        this.sourceUsersTypes = [...this.associateUsersTypes];

        /** Récupération de tous les types de relation de travail de la conf */
        let workRelationshipType = await this.serviceAppWorksContract.getListWorkRelationshipTypes();
        /** L'api conf retourne la liste des ids des type de relation de travail */
        for (let i = 0; i < workRelationshipType.length; i++) {
          let workRelation = this.allWorksRelationType.find((w) => w.id == workRelationshipType[i]);
          if (workRelation) {
            this.associateWorkRealtionshipType.push(workRelation);
          }
        }
        this.sourceWorkRelationshipTypes = [...this.associateWorkRealtionshipType];

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Sauvegarde la conf */
    async save() {
      let success = true;

      try {
        // Enregistrement des types utilisateur
        if (this.userTypesHasChanged) {
          let idUserType = this.associateUsersTypes.map((u) => u.id);

          await this.serviceAppWorksContract.updateListUserTypes(idUserType);
        } 

        // Enregistrement des type de relation de travail
        if (this.workRelationshipTypeHasChanged) {
          let idWorkRelation = this.associateWorkRealtionshipType.map((w) => w.id);

          await this.serviceAppWorksContract.updateListWorkRelationshipTypes(idWorkRelation);
        }
      } catch (error) {
        success = false;
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement de la conf : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
      
      if (success) {
        this.disableAlertQuit();
        this.$router.go(-1);
      }
    },

   
    /** Supprime l'item en paramètre de la liste des associés */
    removeAssociateUserTypes(userType) {
      this.associateUsersTypes.splice(this.associateUsersTypes.findIndex((u) => u.id == userType.id), 1);
    },

    /** Supprime l'item en paramètre de la liste des associés */
    removeWorkRelationshipType(workRelationshipType) {
      this.associateWorkRealtionshipType.splice(this.associateWorkRealtionshipType.findIndex((w) => w.id == workRelationshipType.id), 1);
    },

    /** Ajoute le type utilisateur sélectionné à ceux associés */
    addUserType(userType) {
      if (!userType) return;

      this.associateUsersTypes.push(userType);
      this.selectedUserType = null;
    },

    /** Ajoute le type de relation de travail à ceux associés */
    addWorkRelationshipType(workRelationshipType) {
      if (!workRelationshipType) return;

      this.associateWorkRealtionshipType.push(workRelationshipType);
      this.selectedWorkelationshipType = null;
    },

    // Initialisation des étapes du workflow en fonction des droits de l'utilisateur
    initSteps() {
      this.addStepForWorkflow("Choisir une application");
      this.addStepForWorkflow("Modifier la configuration");
      this.nextStepForWorkflow();
    },

  }, // END methods


  computed: {
    /** Retourne tous les types utilisateurs non associés */
    availableUserType() {
      let available = [];
      let ids = this.associateUsersTypes.map((u) => u.id);
      available = this.allUsersTypes.filter((d) => !ids.includes(d.id));

      return available;
    },

    /** Retourne tous les type de relation de travail non associés */
    availableWorkRelationshipType() {
      let available = [];
      let ids = this.associateWorkRealtionshipType.map((w) => w.id);
      available = this.allWorksRelationType.filter((w) => !ids.includes(w.id));
      return available;
    },

    /** Retourne true si la conf a changé */
    hasChanged() {
      let changed = false;

      if (this.userTypesHasChanged) {
        changed = true;
      }

      if (this.workRelationshipTypeHasChanged) {
        changed = true;
      }

      return changed;
    },

    /** Analyse si la liste des types d'utilisateur a changé */
    userTypesHasChanged() {
      let changed = false;

      this.associateUsersTypes.forEach((ua) => {
        let found = this.sourceUsersTypes.find((ub) => ua.id == ub.id);
        if (!found) { changed = true; }
      });

      if (changed) return true;

      this.sourceUsersTypes.forEach((ua) => {
        let found = this.associateUsersTypes.find((ub) => ua.id == ub.id);
        if (!found) { changed = true; }
      });

      return changed;
    },

    /** Analyse si la liste des types de relation de travail a changé */
    workRelationshipTypeHasChanged() {
      let changed = false;

      this.associateWorkRealtionshipType.forEach((wa) => {
        let found = this.sourceWorkRelationshipTypes.find((wb) => wa.id == wb.id);
        if (!found) { changed = true; }
      });

      if (changed) return true;

      this.sourceWorkRelationshipTypes.forEach((wa) => {
        let found = this.associateWorkRealtionshipType.find((wb) => wa.id == wb.id);
        if (!found) { changed = true; }
      });

      return changed;
    },
  }, // END computed

  mounted() {
    // Initialisation du worflow
    this.initSteps();

    // Instanciation des services
    this.serviceAppWorksContract = new AppWorksContractsService(
      this.$api.getAppWorksContractApi()
    );
    this.serviceUserType = new UsersTypeService(this.$api);
    this.serviceWorkRelationshipType = new WorkRelationshipTypesService(this.$api.getWorkRelationshipTypeApi());

    this.load();
  },

};
</script>

<style></style>
