var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-center",
              attrs: { flat: "", tile: "" },
            },
            [
              _c("Workflow", {
                attrs: {
                  width: "600",
                  height: "180",
                  steps: _vm.workflowSteps,
                  currentStep: _vm.workflowIndex,
                  labelWidth: 200,
                  lineWidth: 140,
                },
              }),
            ],
            1
          ),
          _c(
            "v-row",
            { attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { xl: "6", lg: "8", md: "8", sm: "10", xs: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-center" },
                    [
                      _c("TitleAndReturnComponent", {
                        attrs: { title: "Application Contrat de travail" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { justify: "center", "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            xl: "6",
                            lg: "8",
                            md: "8",
                            sm: "10",
                            xs: "12",
                          },
                        },
                        [
                          _c("v-progress-linear", {
                            attrs: { indeterminate: "", active: _vm.loading },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    { staticClass: "my-4", attrs: { flat: "", outlined: "" } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "font-weight-regular" },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "space-between",
                              },
                            },
                            [_c("div", [_vm._v("Configuration")])]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-5 mt-1",
                              attrs: { "no-gutters": "", align: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "5",
                                    justify: "center",
                                    "align-self": "start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "capitalize font-weight-regular mt-2",
                                    },
                                    [_vm._v(" types utilisateurs ")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "7", "align-self": "start" } },
                                [
                                  _vm._l(
                                    _vm.associateUsersTypes,
                                    function (ut, i) {
                                      return _c(
                                        "div",
                                        { key: i },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-1",
                                              attrs: {
                                                "no-gutters": "",
                                                align: "center",
                                                justify: "space-between",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(_vm._s(ut.label)),
                                              ]),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeAssociateUserTypes(
                                                        ut
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-window-close"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                            justify: "space-between",
                                          },
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items: _vm.availableUserType,
                                              "item-text": "label",
                                              "return-object": "",
                                              placeholder:
                                                "Associer un type d'utilisateur",
                                              hint: "Associer un type d'utilisateur puis appuyer sur le bouton '+'",
                                              clearable: "",
                                              "no-data-text":
                                                "aucun type utlisateur",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.addUserType(
                                                  _vm.selectedUserType
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.selectedUserType,
                                              callback: function ($$v) {
                                                _vm.selectedUserType = $$v
                                              },
                                              expression: "selectedUserType",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                icon: "",
                                                disabled: !_vm.selectedUserType,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addUserType(
                                                    _vm.selectedUserType
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c("v-divider"),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mb-5 mt-1",
                              attrs: { "no-gutters": "", align: "center" },
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "5",
                                    justify: "center",
                                    "align-self": "start",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "capitalize font-weight-regular mt-2",
                                    },
                                    [_vm._v(" natures relation de travail ")]
                                  ),
                                ]
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "7", "align-self": "start" } },
                                [
                                  _vm._l(
                                    _vm.associateWorkRealtionshipType,
                                    function (nrt, i) {
                                      return _c(
                                        "div",
                                        { key: i + 1000 },
                                        [
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mb-1",
                                              attrs: {
                                                "no-gutters": "",
                                                align: "center",
                                                justify: "space-between",
                                              },
                                            },
                                            [
                                              _c("div", [
                                                _vm._v(_vm._s(nrt.label)),
                                              ]),
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    icon: "",
                                                    color: "primary",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeWorkRelationshipType(
                                                        nrt
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-window-close"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          attrs: {
                                            "no-gutters": "",
                                            align: "center",
                                            justify: "space-between",
                                          },
                                        },
                                        [
                                          _c("v-autocomplete", {
                                            attrs: {
                                              items:
                                                _vm.availableWorkRelationshipType,
                                              "item-text": "label",
                                              "return-object": "",
                                              placeholder:
                                                "Associer une nature de relation de travail",
                                              hint: "Associer une nature de relation de travail puis appuyer sur le bouton '+'",
                                              clearable: "",
                                              "no-data-text":
                                                "aucune nature relation de travail",
                                            },
                                            on: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.addWorkRelationshipType(
                                                  _vm.selectedWorkelationshipType
                                                )
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.selectedWorkelationshipType,
                                              callback: function ($$v) {
                                                _vm.selectedWorkelationshipType =
                                                  $$v
                                              },
                                              expression:
                                                "selectedWorkelationshipType",
                                            },
                                          }),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "primary",
                                                icon: "",
                                                disabled:
                                                  !_vm.selectedWorkelationshipType,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addWorkRelationshipType(
                                                    _vm.selectedWorkelationshipType
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-plus"),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-2 px-4 btn",
                              attrs: {
                                outlinedq: "",
                                color: "primary",
                                disabled: !_vm.hasChanged,
                              },
                              on: { click: _vm.save },
                            },
                            [
                              _c("div", { staticClass: "capitalize" }, [
                                _vm._v(" enregistrer "),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AlertNotSavedModifsComponent", {
        attrs: { show: _vm.showAlertQuit },
        on: { quit: _vm.onQuitAlert, notquit: _vm.onNotQuitAlert },
      }),
      _c(
        "v-snackbar",
        {
          attrs: {
            color: _vm.snackbarColor,
            timeout: _vm.snackbarTimeout,
            left: _vm.snackbarLeft,
            right: _vm.snackbarRight,
            top: _vm.snackbarTop,
            bottom: _vm.snackbarBottom,
          },
          model: {
            value: _vm.snackbarVisible,
            callback: function ($$v) {
              _vm.snackbarVisible = $$v
            },
            expression: "snackbarVisible",
          },
        },
        [_vm._v(_vm._s(_vm.snackbarMessage))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }