import * as statusMapper from "@/service/error_request_mapper.js";


export class AppWorksContractsService {
  constructor(api) {
    this.api = api;
  }

  /** Obtention de la liste des types utilisateurs  */
  async getListUserTypes() {
    return this.api
    .getListUserTypes()
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Mise à jour de la listes des types utilisateurs */
  async updateListUserTypes(entities) {
    return this.api
    .updateListUserTypes(entities)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }


  /** Obtention de la liste des types de relation de travail */
  async getListWorkRelationshipTypes() {
    return this.api
    .getListWorkRelationshipTypes()
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /** Mise à jour de la listes des types de relation de travail */
  async updateListWorkRelationshipTypes(entities) {
    return this.api
    .updateListWorkRelationshipTypes(entities)
    .catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

}